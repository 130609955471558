import { rest } from 'msw'
import { headersToObject } from 'headers-polyfill'
import { DESIGN_API_HOST, QA_API_HOST, sandboxApi } from 'const/config'
import { testEndpointUrl } from 'services/testQuery'
import {
  AccountTypeMapping,
  accountActivityUrl,
  accountSummaryV2GetHandler as accountSummaryV2GetHandlerPaylater,
  autopayStatusUrl,
  creditAccountsQueryGetHandler
} from 'syf-api'
import {
  accountFeaturesUrl,
  paymentEnterpriseEligibilityUrl,
  paymentMethodsUrl,
  paymentQueryUrl,
  settlementEligibilityUrl,
  statementsUrl
} from 'syf-api/api/endpoints'
import mockAuthData from 'mocks/mockData/auth/mockAuthData'
import { introspectURL } from 'services/introspectQuery/introspectQuery'
import {
  activationAuthenticateURL,
  authenticateURL,
  paylaterAuthenticateURL
} from 'services/authenticateMutation/authenticateMutation.constants'
import { completeURL } from 'services/completeMutation/completeMutationOptions'
import { authorizeURL } from 'services/authorizeMutation/authorizeMutationOptions'
import { getMockAccountIds } from 'mocks/mockData'
import { SECURED_INSTALLMENTS_FLOW } from 'const/constants'
import getSessionAuthFlow from 'helpers/authFlows/getSessionAuthFlow'
import sleep from 'helpers/sleep'
import mockActivationAuthData from 'mocks/mockData/auth/activation/mockActivationAuthData'
import { PRE_LOGIN_ACTIVATE_URL } from 'const/universalActivation'
import { mockProfileData } from './mockData/profile/mockProfileData'
import { mockAlertData } from './mockData/alerts/mockAlertsData'
import {
  mockAccountFeatures,
  mockActivity,
  mockAutopayStatus,
  mockAutopayStatusEnrolled,
  mockMMAConfig,
  mockPaymentEnterpriseEligibility,
  mockPaymentMethods,
  mockSettlementEligibility,
  mockStatements
} from './mockData'
import { mockPreferencesData } from './mockData/alerts/mockPreferencesData'
import accountSummarySecuredInstallmentsMock from './mockData/accountSummary/securedInstallments'
import {
  activationSuccessResponse,
  cardActivationErrors,
  getActivationCustomErrorResponse
} from './mockData/card/activation/mockCardActivationData'
import { MOCK_ACTIVATION_CUSTOM_ERROR_CODE } from './ui/const/toggles'

/** Whether the environment is a browser or test runner */
export const isBrowserEnvironment = process.env.NODE_ENV !== 'test'

/**
 * Setup file for MSW (Mock Service Worker) to mock api calls
 * https://mswjs.io/docs/getting-started/mocks/rest-api
 */

/** Prefixes passed in path with sandboxApi value from config */
export const mswUrl = (path: string): string => new URL(path, sandboxApi).href

/** URLs requests to catch and mock */
export const mockUrls = {
  test: `${new URL(testEndpointUrl, sandboxApi)}`,
  // have to use a wildcard for auth module fetches to be caught
  token: '*/oauth2/token',
  introspect: `*/${introspectURL}`,
  piiAuthenticate: `*/${paylaterAuthenticateURL}`,
  authentication: `*/${authenticateURL}`,
  activationAuthenticate: `*/${activationAuthenticateURL}`,
  complete: `*/${completeURL}`,
  authorize: `*/${authorizeURL}`,
  // a config file that mma mfe requests
  mmaConfig: '*/prod-mma-config.json',
  // services
  accountActivity: `*/${accountActivityUrl}`,
  autopay: `*/${autopayStatusUrl}`,
  enterpriseEligibility: `*/${paymentEnterpriseEligibilityUrl}`,
  paymentMethods: `*/${paymentMethodsUrl}`,
  settlementEligibility: `*/${settlementEligibilityUrl}`,
  accountFeatures: `*/${accountFeaturesUrl}`,
  statements: `*/${statementsUrl}`,
  // for AlertsMFE
  profile: '*/credit/accounts/profile',
  alerts: '*/alerts',
  alertsPreferences: '*/alerts/preferences',
  summary: '*/credit/accounts/summary',
  preLoginActivate: `*/${PRE_LOGIN_ACTIVATE_URL}`,
  paymentQuery: `*/${paymentQueryUrl}`
}

const accountTypeMapping: AccountTypeMapping = {
  '2ec7be46-00f2-4367-9ef8-acb42e3a7ca3': 'INSTALLMENT_LOAN_PAY_IN_FOUR',
  'i5k38m4g-0h0g-404g-h7jg-1851i58j7h3g': 'INSTALLMENT_LOAN_MONTHLY'
}

const hostUrl = sandboxApi

// special case response mockers

/** Mock error response to testQuery */
export const testErrorGetHandler = rest.get(
  mockUrls.test,
  (_request, response, ctx) => {
    return response(ctx.status(400), ctx.json(mockAuthData.test(false)))
  }
)

export const testNoContentPostHandler = rest.post(
  mockUrls.test,
  (_request, response, ctx) => {
    return response(ctx.status(204), ctx.json(''))
  }
)

export const tokenServicePostErrorHandler = rest.post(
  mockUrls.token,
  (_request, response, ctx) => {
    return response(ctx.status(422), ctx.json({}))
  }
)

export const tokenPostHandler = rest.post(
  mockUrls.token,
  (_request, response, ctx) => {
    const noActiveAccounts =
      sessionStorage.getItem('no_active_accounts') === 'true'

    return response(
      ctx.status(200),
      ctx.json({
        ...mockAuthData.token,
        accountIdentifier: noActiveAccounts ? [] : getMockAccountIds()
      })
    )
  }
)

export const tokenErrorPostHandler = rest.post(
  mockUrls.token,
  (_request, response, ctx) => {
    return response(ctx.status(500), ctx.json({}))
  }
)

export const authenticateErrorIovationPostHandler = rest.post(
  mockUrls.piiAuthenticate,
  (_request, response, ctx) => {
    return response(
      ctx.status(422),
      ctx.json(mockAuthData.authenticateErrorIovation)
    )
  }
)

export const authenticateErrorSessionInvalidPostHandler = rest.post(
  mockUrls.piiAuthenticate,
  (_request, response, ctx) => {
    return response(
      ctx.status(401),
      ctx.json(mockAuthData.authenticateErrorFailed)
    )
  }
)

export const authenticateErrorSessionNotFoundPostHandler = rest.post(
  mockUrls.piiAuthenticate,
  (_request, response, ctx) => {
    return response(
      ctx.status(422),
      ctx.json(mockAuthData.authenticateErrorSessionNotFound)
    )
  }
)

export const authenticateErrorBlockedAccountPostHandler = rest.post(
  mockUrls.authentication,
  (_request, response, ctx) => {
    return response(
      ctx.status(422),
      ctx.json(mockAuthData.authenticateErrorBlockedAccount)
    )
  }
)

export const accountSummaryV2HandlerQA = accountSummaryV2GetHandlerPaylater(
  { hostUrl: QA_API_HOST },
  accountTypeMapping
)

const accountSummaryV2GetHandler = () => {
  if (getSessionAuthFlow() === SECURED_INSTALLMENTS_FLOW) {
    return rest.get(mockUrls.summary, (_request, response, ctx) => {
      return response(
        ctx.status(200),
        ctx.delay(300),
        ctx.json(accountSummarySecuredInstallmentsMock())
      )
    })
  }
  return accountSummaryV2GetHandlerPaylater(
    { hostUrl, delayMs: isBrowserEnvironment ? 1500 : 0 },
    accountTypeMapping
  )
}

export const autopayStatusEnrolledGetHandler = rest.get(
  mockUrls.autopay,
  (_request, response, ctx) => {
    return response(
      ctx.status(200),
      ctx.delay(300),
      ctx.json(mockAutopayStatusEnrolled)
    )
  }
)

export const universalActivationErrorAccountUnsupportedPostHandler = rest.post(
  mockUrls.activationAuthenticate,
  (_request, response, ctx) => {
    return response(
      ctx.status(422),
      ctx.json(mockActivationAuthData.accountUnsupportedError)
    )
  }
)

export const universalActivationErrorIovationPostHandler = rest.post(
  mockUrls.activationAuthenticate,
  (_request, response, ctx) => {
    return response(
      ctx.status(422),
      ctx.json(mockActivationAuthData.iovationError)
    )
  }
)

export const universalActivationErrorMaxAttemptsPostHandler = rest.post(
  mockUrls.activationAuthenticate,
  (_request, response, ctx) => {
    return response(
      ctx.status(429),
      ctx.json(mockActivationAuthData.maxAttemptsError)
    )
  }
)

// proxies/patchers - when mocking, calls other actual apis

export const authenticateActivationApiDesignProxyPostHandler = rest.post(
  mockUrls.activationAuthenticate,
  async (_request, response, ctx) => {
    /*
     * Proxy idea inspired by https://github.com/mswjs/msw/discussions/887
     * Read more: https://v1.mswjs.io/docs/api/request https://v1.mswjs.io/docs/api/context/fetch
     */
    const { mode, method, headers } = _request
    const proxyURL = new URL(
      'v1/custid-helper/quick-authenticate-2',
      DESIGN_API_HOST
    )
    const requestBody = await _request.json()
    const proxiedRequest = {
      method,
      mode,
      headers,
      body: JSON.stringify(requestBody)
    }

    const proxiedFetch = await ctx.fetch(proxyURL.href, proxiedRequest)
    return response(
      ctx.status(proxiedFetch.status),
      ctx.set(headersToObject(proxiedFetch.headers)),
      ctx.body(await proxiedFetch.text())
    )
  }
)

// default mock response mockers
export const testGetHandler = rest.get(
  mswUrl(mockUrls.test),
  (_request, response, ctx) => {
    const { url } = _request
    const isForceError = url.searchParams.get('error') === 'true'
    if (isForceError) {
      return response(ctx.status(400), ctx.json(mockAuthData.test(false)))
    }
    return response(ctx.status(200), ctx.json(mockAuthData.test()))
  }
)

export const introspectPostHandler = rest.post(
  mockUrls.introspect,
  async (_request, response, ctx) => {
    const invalidToken = sessionStorage.getItem('invalid_token') === 'true'
    const expiredToken = sessionStorage.getItem('expired_token') === 'true'
    const delayIntrospect =
      sessionStorage.getItem('delay_introspect') === 'true'
    if (expiredToken) {
      // this fixes a potential infinite loop, if expired token is not reset after the page refreshes
      sessionStorage.setItem('expired_token', 'false')
      return response(
        ctx.status(200),
        ctx.json(mockAuthData.introspectExpiredToken)
      )
    }
    if (invalidToken) {
      // this fixes a potential infinite loop, if invalid token is not reset after the page refreshes
      sessionStorage.setItem('invalid_token', 'false')
      return response(
        ctx.status(200),
        ctx.json(mockAuthData.introspectInvalidToken)
      )
    }

    if (delayIntrospect) {
      await sleep(10000)
    }

    return response(ctx.status(200), ctx.json(mockAuthData.introspect))
  }
)

export const authenticatePostHandler = rest.post(
  mockUrls.authentication,
  (_request, response, ctx) => {
    const accountNotFound =
      sessionStorage.getItem('account_not_found') === 'true'
    if (accountNotFound) {
      return response(
        ctx.status(422),
        ctx.json(mockAuthData.authenticateErrorSecuredNotFound)
      )
    }
    return response(ctx.status(200), ctx.json(mockAuthData.authenticateSecured))
  }
)

export const universalActivationQuickAuthHandler = rest.post(
  mockUrls.activationAuthenticate,
  (_request, response, ctx) => {
    const accountNotFound =
      sessionStorage.getItem('account_not_found') === 'true'
    if (accountNotFound) {
      return response(
        ctx.status(422),
        ctx.json(
          mockActivationAuthData.authenticateErrorUniversalActivationNotFound
        )
      )
    }
    return response(
      ctx.status(200),
      ctx.json(mockActivationAuthData.authenticateSuccessResponse)
    )
  }
)

export const universalActivationFetchAccountErrorQuickAuthHandler = rest.post(
  mockUrls.activationAuthenticate,
  (_request, response, ctx) => {
    return response(
      ctx.status(500),
      ctx.json(mockActivationAuthData.fetchAccountError)
    )
  }
)

export const piiAuthenticatePostHandler = rest.post(
  mockUrls.piiAuthenticate,
  (_request, response, ctx) => {
    const accountNotFound =
      sessionStorage.getItem('account_not_found') === 'true'
    if (accountNotFound) {
      return response(
        ctx.status(422),
        ctx.json(mockAuthData.authenticateErrorNotFound)
      )
    }
    return response(ctx.status(200), ctx.json(mockAuthData.authenticate))
  }
)

export const completeGetHandler = rest.get(
  mockUrls.complete,
  (_request, response, ctx) => {
    const clientIdErr = sessionStorage.getItem('client_error') === 'true'
    if (clientIdErr) {
      return response(ctx.status(422), ctx.json(mockAuthData.completeError))
    }
    return response(ctx.status(200), ctx.json(mockAuthData.complete))
  }
)
/** Used by mma-mfe, MAP to retrieve activity */
const accountActivityQueryGetHandler = rest.get(
  mockUrls.accountActivity,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockActivity))
  }
)

// some config obj for mma-mfe
const mmaConfigGetHandler = rest.get(
  mockUrls.mmaConfig,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockMMAConfig))
  }
)

const accountFeaturesHandler = rest.get(
  mockUrls.accountFeatures,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockAccountFeatures))
  }
)

const statementsHandler = rest.get(
  mockUrls.statements,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockStatements))
  }
)

/** Used by mma-mfe, MAP */
const autopayStatusGetHandler = rest.get(
  mockUrls.autopay,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockAutopayStatus))
  }
)

/** Used by MAP when autopay enrollment succeeds on pre 1st cycle acct */
const autopayStatusPutHandler = rest.put(
  mockUrls.autopay,
  (_request, response, ctx) => {
    return response(
      ctx.status(202),
      ctx.set('Content-Type', 'application/text'),
      ctx.text('')
    )
  }
)

/** Used by payment enterprise eligibility, MAP */
const enterpriseEligibilityGetHandler = rest.get(
  mockUrls.enterpriseEligibility,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockPaymentEnterpriseEligibility))
  }
)

/** Used by payment methods, MAP */
const paymentMethodsGetHandler = rest.get(
  mockUrls.paymentMethods,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockPaymentMethods))
  }
)
/** Used by payment plan settlement, MAP */
const settlementEligibilityGetHandler = rest.get(
  mockUrls.settlementEligibility,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockSettlementEligibility))
  }
)
/** Used by MAP */
const postPaymentHandler = rest.post(
  mockUrls.paymentQuery,
  (_request, response, ctx) => {
    const paymentId = '1234567'
    return response(ctx.status(200), ctx.json({ paymentId }))
  }
)

/** Used for Alerts MFE */
const profileGetHandler = rest.get(
  mockUrls.profile,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockProfileData))
  }
)

const alertsGetHandler = rest.get(
  mockUrls.alerts,
  (_request, response, ctx) => {
    return response(ctx.status(200), ctx.json(mockAlertData))
  }
)

const alertsPreferenceGetHandler = rest.get(
  mockUrls.alertsPreferences,
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockPreferencesData))
  }
)

/** card activation handlers */
const cardActivationPutHandler = rest.put(
  mockUrls.preLoginActivate,
  (_request, response, ctx) => {
    const activationCustomErrorCode = sessionStorage.getItem(
      MOCK_ACTIVATION_CUSTOM_ERROR_CODE
    )
    if (activationCustomErrorCode) {
      return response(
        ctx.delay(2000),
        ctx.status(422),
        ctx.json(getActivationCustomErrorResponse(activationCustomErrorCode))
      )
    }
    return response(
      ctx.delay(2000),
      ctx.status(200),
      ctx.json(activationSuccessResponse)
    )
  }
)

export const cardActivationExternalStatusErrorHandler = rest.put(
  mockUrls.preLoginActivate,
  (_request, response, ctx) => {
    return response(
      ctx.delay(2000),
      ctx.status(422),
      ctx.json(cardActivationErrors.externalStatusErrorMock)
    )
  }
)

export const cardActivationInvalidExternalStatusErrorHandler = rest.put(
  mockUrls.preLoginActivate,
  (_request, response, ctx) => {
    return response(
      ctx.delay(2000),
      ctx.status(422),
      ctx.json(cardActivationErrors.invalidExternalStatusErrorMock)
    )
  }
)

export const cardActivationAuthShellErrorHandler = rest.put(
  mockUrls.preLoginActivate,
  (_request, response, ctx) => {
    return response(
      ctx.delay(2000),
      ctx.status(422),
      ctx.json(cardActivationErrors.authShellAccountErrorMock)
    )
  }
)

export const cardActivationAlreadyActivatedErrorHandler = rest.put(
  mockUrls.preLoginActivate,
  (_request, response, ctx) => {
    return response(
      ctx.delay(2000),
      ctx.status(422),
      ctx.json(cardActivationErrors.alreadyActivatedStatusErrorMock)
    )
  }
)
// these handlers define the mock response to give for specific urls
const handlers = [
  introspectPostHandler,
  completeGetHandler,
  accountActivityQueryGetHandler,
  creditAccountsQueryGetHandler({ hostUrl }),
  accountSummaryV2GetHandler(),
  authenticatePostHandler,
  piiAuthenticatePostHandler,
  autopayStatusGetHandler,
  autopayStatusPutHandler,
  tokenPostHandler,
  enterpriseEligibilityGetHandler,
  paymentMethodsGetHandler,
  settlementEligibilityGetHandler,
  postPaymentHandler,
  mmaConfigGetHandler,
  statementsHandler,
  accountFeaturesHandler,
  profileGetHandler,
  alertsGetHandler,
  alertsPreferenceGetHandler,
  cardActivationPutHandler,
  universalActivationQuickAuthHandler
]

export default handlers
