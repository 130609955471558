/**
 * This call logs error info to new relic as a JavascriptError event.
 * Useful when you have something catching errors so you can show nice fallbacks instead so need
 * to manually send error info to new relic.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/noticeerror/
 * Note: Tested with various signin queries that trigger error boundaries and those all
 * prevents noticeError from working after, even though they hit an error boundary.
 *
 * @param {string} errorMessage - str - an error message to show in new relic errors tab
 * @param {object} [errorData] - obj - optional data object of name: value attributes to log with error in new relic.
 * Note: 255 character limit per attribute:
 * https://docs.newrelic.com/docs/data-apis/custom-data/custom-events/data-requirements-limits-custom-event-data/
 * @returns {string} string of error msg set or empty str if not
 */
const setNewRelicNoticeError = (
  errorMessage: string,
  errorData?: Record<string, newrelic.SimpleType>
): string => {
  const { newrelic } = window
  if (!newrelic) {
    return ''
  }

  try {
    newrelic.noticeError(new Error(errorMessage), errorData)
    return `${errorMessage}`
  } catch (e) {
    console.warn(
      'New Relic noticeError function unavailable, update the New Relic script or ensure it has fully loaded before function is invoked.'
    )
    return ''
  }
}

export default setNewRelicNoticeError
