/**
 * This function pauses the execution for a specified number of milliseconds.
 * It returns a Promise that resolves after the specified time, allowing to async/await it.
 * @param timeMs {number} - number of milliseconds to wait before resolving the promise.
 * @returns {Promise} - promise that resolves after the provided delay time.
 */
const sleep = (timeMs: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeMs)
  })
}

export default sleep
